#menu-toggle {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 2;
}

#sidebar-wrapper {
    height: 100%;
    left: 0;
    width: 250px;
    z-index: 1;
    overflow-y: auto;
}

#sidebar-wrapper.collapsed {
    width: 50px;
}

.subCategoryList {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
}
.subCategoryList.active {
    max-height: 300px; /* This should be big enough to fit your content */
}

.subCategoryHoverPopup {
    display: none;
    position: absolute;
    left: 72px; /* or any value that works well */
    background: white;
    border: 1px solid gray;
    z-index: 1000;
  }
  
  .show {
    display: block;
  }