body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  margin-left: 240px; /* width of sidebar */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px); /* height of navbar */
  overflow: auto; /* This will allow scrolling if the content exceeds the container */
}

.chart-container {
  min-width: 700px; /* Adjust to your desired minimum width */
  max-width: 900px; /* Adjust to your desired maximum width */
}

/* Style Paper component to take up max available width */
.Paper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto; /* This will ensure the table can be scrolled horizontally if it exceeds the container */
}

/* Ensure table stretches to the width of its container */
.Table {
  width: 100%;
}

.TablePagination {
  margin: 0;
  padding: 0;
}

.date-input {
  font-size: 16px;
  padding: 8px;
  border: none;
  background: #333; /* match with your theme */
  color: #fff; /* match with your theme */
  border-radius: 5px;
  margin-top: 10px;
}

.calendar-custom-style {
  background: #222; /* match with your theme */
  border-radius: 10px;
}

.select-container {
  width: 45%; /* this gives each select about half the container's width */
}